class WappoTable extends HTMLElement {
    rowHeight = 25;
    extraRows = 6;
    visibleRowCount = -1;

    _cellsWidths = [];
    _cellLefts = [];
    _columnKeys = [];

    constructor() {
        super();
    }

    setItems(items) {
        this._items = items;
        this._body.style.height = (this._items.length * this.rowHeight) + 'px';
        this.setVisibleData();
    }

    setVisibleData() {
        const start = Math.floor(this._viewport.scrollTop / this.rowHeight);
        const renderStart = start - (this.extraRows / 2);
        const itemsToRender = this.visibleRowCount + this.extraRows;
        const _pathCache = {};

        /**
         * 
         * @param {Object} item 
         * @param {string} path 
         * @returns 
         */
        const resolve = (item, path) => {
            if (path in _pathCache) {
                return _pathCache[path](item);
            }

            let resolver = (_item) => _item[path];
            if (path.includes('.')) {
                const parts = path.split('.');
                resolver = (_item) => {
                    let _c = _item;
                    for (let p of parts) {
                        _c = _c[p];
                        if (!_c) {
                            return null;
                        }
                    }

                    return _c;
                }
            }

            _pathCache[path] = resolver;
            return _pathCache[path](item);
        }

        for (let i = 0; i < itemsToRender; i++) {
            const item = this._items[i + renderStart];
            const row = this._rows[i];

            for (let x = 0; x < this._columnKeys.length; x++) {
               // this._applyCellPosition(row.children[x], x);
                row.children[x].innerText = item ? resolve(item, this._columnKeys[x]) : '';
            }

            this._applyRowPosition(row, i);
            if (!item) {
                row.style.display = 'none';
            } else {
                row.style.display = 'block';
            }
        }
    }

    _setColumnWith(index, width) {
        const columns = this.querySelectorAll('wa-table-column');
        let availableWidth = this.offsetWidth;
        let left = 0;

        for (let i = 0; i < columns.length; i++) {
            let colWidth = Math.min(availableWidth, (i === index ? width : columns[i].offsetWidth));
            if (i === columns.length - 1) {
                colWidth = availableWidth;
            }

            this._cellsWidths[i] = colWidth;
            this._cellLefts[i] = left;

            left += colWidth;
            availableWidth -= colWidth;

            this._positionColumn(columns[i], i);
            this._positionCells(i);
        }
    }

    _positionColumn(column, index) {
        column.style.width = this._cellsWidths[index] + 'px';
        column.style.left = this._cellLefts[index] + 'px';
    }

    _positionCells(colIndex) {
        for (let i = 0; i < this._cells[colIndex].length; i++) {
            this._applyCellPosition(this._cells[colIndex][i], colIndex);
        }
    }

    _parseColumns() {
        const columns = this.querySelectorAll('wa-table-column');
        const availableWidth = this.offsetWidth;
        const columnWidth = Math.floor(availableWidth / columns.length);
        let left = 0;

        for (let i = 0; i < columns.length; i++) {
            this._cellsWidths[i] = columnWidth;
            this._cellLefts[i] = left;
            left += columnWidth;
        }

        for (let i = 0; i < columns.length; i++) {
            columns[i].style.width = this._cellsWidths[i] + 'px';
            columns[i].style.left = this._cellLefts[i] + 'px';

            columns[i].addEventListener('resize', (e) => {
                const { width } = e.detail;
                this._setColumnWith(i, width);
            });

            this._columnKeys.push(columns[i].getAttribute('key'));
        }
    }

    _applyCellPosition(cell, index) {
        const width = this._cellsWidths[index];
        const left = this._cellLefts[index];

        cell.style.width = width + 'px';
        cell.style.left = left + 'px';
    }

    _applyRowPosition(row, index) {
        const before = this.extraRows / 2;
        const visibleIndex = index - before;
        const hidden = Math.floor(this._viewport.scrollTop / this.rowHeight);
        const hiddenY = hidden * this.rowHeight;
        const yPos = (this._viewport.scrollTop + (this.rowHeight * visibleIndex)) - (this._viewport.scrollTop - hiddenY);

        row.style.transform = 'translate(0, ' + yPos + 'px)';
    }

    _buildRows() {
        this.visibleRowCount = Math.ceil(this.getBoundingClientRect().height / this.rowHeight);
        const visibleRows = this.visibleRowCount + this.extraRows;
        const columnCount =  this.querySelectorAll('wa-table-column').length;
        this._rows = [];
        this._cells = [];

        for (let i = 0; i < columnCount; i++) {
            this._cells.push([]);
        }

        for (let i = 0; i < visibleRows; i++) {
            const row = document.createElement('wa-table-row');
            for (let x = 0; x < columnCount; x++) {
                const cell = document.createElement('wa-table-cell');
                cell.setAttribute('row', i);
                cell.setAttribute('col', x);
                this._applyCellPosition(cell, x);
                row.append(cell);

                this._cells[x].push(cell);
            }

            this._applyRowPosition(row, i);
            this._body.appendChild(row);
            this._rows.push(
                row
            );
        }
    }

    viewport_scroll(e) {
        this.setVisibleData();
    }

    _buildDom() {
        this._header = document.createElement('wa-table-header');
        this._viewport = document.createElement('wa-table-viewport');
        this._body = document.createElement('wa-table-body');

        this.append(this._header, this._viewport);
        this._viewport.append(this._body);
        this._viewport.addEventListener('scroll', (e) => this.viewport_scroll(e));

        this.querySelectorAll('wa-table-column').forEach(col => this._header.appendChild(col));
    }

    _repositionViewport() {
        this._viewport.style.height = (this.getBoundingClientRect().height - this._header.getBoundingClientRect().height) + 'px';
    }

    connectedCallback() {
        this._buildDom();
        this._parseColumns();
        this._buildRows();
        this._repositionViewport();
    }

    disconnectedCallback() {
        console.log("Custom element removed from page.");
    }

    adoptedCallback() {
        console.log("Custom element moved to new page.");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        console.log(`Attribute ${name} has changed.`);
    }
}

class WappoTableColumn extends HTMLElement {
    static observedAttributes = ["key", "resizable"];

    isResizing = false;

    _clientX = 0;

    constructor() {
        super();
    }

    _buildDom() {
        this._resizeHandle = document.createElement('wa-table-column-resize-handle');
        this.appendChild(this._resizeHandle);
    }

    _bindEvents() {
        this._resizeHandle.addEventListener('mousedown', (e) => {
            this.isResizing = true;
            this._clientX = e.clientX;
            this._width = this.offsetWidth;
        });

        document.body.addEventListener('mousemove', (e) => {
            if (this.isResizing) {
                const width = this._width + (e.clientX - this._clientX);
                this.dispatchEvent(new CustomEvent('resize', {
                    detail: { width }
                }));
            }
        });

        document.body.addEventListener('mouseup', () => {
            this.isResizing = false;
        });
    }

    connectedCallback() {
        this._buildDom();
        this._bindEvents();
    }

    disconnectedCallback() {
        console.log("Custom element removed from page.");
    }

    adoptedCallback() {
        console.log("Custom element moved to new page.");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        console.log(`Attribute ${name} has changed.`);
    }
}

class WappoTableColumnResizeHandle extends HTMLElement {

}

class WappoTableHeader extends HTMLElement {

}

class WappoTableViewport extends HTMLElement {}
class WappoTableBody extends HTMLElement {}

class WappoTableRow extends HTMLElement {}
class WappoTableCell extends HTMLElement {}

customElements.define("wa-table", WappoTable);
customElements.define("wa-table-column", WappoTableColumn);
customElements.define('wa-table-header', WappoTableHeader);
customElements.define('wa-table-viewport', WappoTableViewport);
customElements.define('wa-table-body', WappoTableBody);
customElements.define('wa-table-row', WappoTableRow);
customElements.define('wa-table-cell', WappoTableCell);
customElements.define('wa-table-column-resize-handle', WappoTableColumnResizeHandle);